import { FormEvent, SetStateAction } from "react"

import { SelectChangeEvent } from "@mui/material/Select"
import { Theme } from "@mui/material/styles"

import { IAddOrUpdateMemberResponse } from "@nhi/newsletter/mailchimp/types"
import {
    INewsletter,
    NEWSLETTER_TYPE
} from "data/mailchimp/interfaces/INewsletter"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import { validateEmail } from "lib/validateEmail"

export type FormInterest = {
    fieldTitle: string
    intererestsId: string
}

export type FormMergeFields = {
    fieldName: string
    value: string
}

export interface IFormValues {
    email: string
    listId: NEWSLETTER_TYPE
    interests: FormInterest[]
    merge_fields: FormMergeFields[]
}

// get the initial values for the form based on the newsletterId
export function getInitialFormValues(newsletterId: NEWSLETTER_TYPE) {
    return {
        email: "",
        listId: newsletterId,
        interests: [],
        merge_fields: []
    } as IFormValues
}

// update the form state for interests and mergefields
export function handleSelectChange(
    event: SelectChangeEvent,
    type: string,
    form: IFormValues,
    setForm: (value: SetStateAction<IFormValues>) => void,
    tag?: string
) {
    if (type === "interests") {
        const prevState = [...form.interests]

        const existingFieldIndex = prevState.findIndex(
            item => item.fieldTitle === event.target.name
        )
        if (existingFieldIndex !== -1)
            prevState[existingFieldIndex].intererestsId = event.target.value
        else
            prevState.push({
                fieldTitle: event.target.name,
                intererestsId: event.target.value
            })

        setForm({ ...form, interests: prevState })
    } else if (type === "mergefields" && tag) {
        const prevState = [...form.merge_fields]

        const existingFieldIndex = prevState.findIndex(
            item => item.fieldName === tag
        )
        if (existingFieldIndex !== -1)
            prevState[existingFieldIndex].value = event.target.value
        else
            prevState.push({
                fieldName: tag,
                value: event.target.value
            })

        setForm({ ...form, merge_fields: prevState })
    }
}

// update the form  state for email
export function handleEmailChange(
    input: string,
    form: IFormValues,
    setForm: (value: SetStateAction<IFormValues>) => void,
    setEmailError: (value: SetStateAction<boolean>) => void
) {
    const inputValue = input
    setForm({
        ...form,
        email: inputValue
    })
    if (!validateEmail(inputValue)) setEmailError(true)
    else setEmailError(false)
}

// handle the sign up form submission
export async function handleSignUp(
    e: FormEvent<HTMLDivElement>,
    emailError: boolean,
    form: IFormValues,
    setIsSending: (value: SetStateAction<boolean>) => void,
    setSuccessfullySubscribed: (
        value: SetStateAction<boolean | undefined>
    ) => void,
    setNewsletterSubscription: (
        value: INewsletter
    ) => Promise<IAddOrUpdateMemberResponse>
) {
    e.preventDefault()
    if (emailError) return

    const formattedInterests = getFormattedInterests(form)
    const formattedMergefields = getFormattedMergefields(form)

    setIsSending(true)
    const res = await setNewsletterSubscription({
        email: form.email,
        newsletterId: form.listId,
        interests: formattedInterests,
        merge_fields: formattedMergefields
    } as INewsletter)

    setIsSending(false)
    if (res.status !== "subscribed") setSuccessfullySubscribed(false)
    else setSuccessfullySubscribed(true)
}

// clear all fields in the form
export function clearAllFields(
    setForm: (value: SetStateAction<IFormValues>) => void,
    initialFormValues: IFormValues,
    setEmailError: (value: SetStateAction<boolean>) => void,
    setSuccessfullySubscribed: (
        value: SetStateAction<boolean | undefined>
    ) => void
) {
    setForm(initialFormValues)
    setEmailError(false)
    setSuccessfullySubscribed(undefined)
}

// get the render value for the interest fields
export function getInterestRenderValue(title: string, form: IFormValues) {
    const existingField = form.interests.find(item => item.fieldTitle === title)
    return existingField ? existingField.intererestsId : ""
}

// get the render value for the mergefields
export function getMergefieldsRenderValue(tag: string, form: IFormValues) {
    const selectedValue = form.merge_fields.find(item => item.fieldName === tag)
    return selectedValue ? selectedValue.value : ""
}

// list of mergefields from state converted to object acceptable to mailchimp api
function getFormattedInterests(form: IFormValues) {
    const interestsState = form.interests.reduce((acc, curr) => {
        acc[curr.intererestsId as string] = true
        return acc
    }, {} as { [key: string]: boolean })
    return interestsState
}

// list of mergefields from state converted to object acceptable to mailchimp api
function getFormattedMergefields(form: IFormValues) {
    const mergeFieldsState = form.merge_fields.reduce((acc, curr) => {
        acc[curr.fieldName] = curr.value
        return acc
    }, {} as { [key: string]: string })
    return mergeFieldsState
}

// get the correct palette based on the newsletterId
export function getNewsletterPalette(
    newsletterId: NEWSLETTER_TYPE,
    theme: Theme
) {
    let palette
    newsletterId === NEWSLETTER_TYPE.NEWSLETTER_PREGNANCY
        ? (palette = theme.palette.pregnancy)
        : (palette = theme.palette.newsletter)

    return palette
}

// sometimes we want to force the component to render narrow based on where the component is used
export function getLayoutType(
    renderFullWidth: boolean | undefined,
    isMdUp: boolean
) {
    const showAsFullWidth = renderFullWidth === false ? false : isMdUp
    return showAsFullWidth
}

// device dimensions for the newsletter component used to render scelton loader
export const newsletterDeviceDimensions: IDeviceDimensions = {
    sm: {
        height: 400
    },
    md: {
        height: 250
    },
    lg: {
        height: 200
    }
}
